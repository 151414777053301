import React from 'react';
import { connect } from 'react-redux';
import MainMenu from './editors/mainEditor/mainMenu';
import Canvas from './canvas/canvas';
import { MuiThemeProvider } from "@material-ui/core";
import { createTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ComponentEditor from './editors/componentEditor/componentEditor';
import ViewEditor from './editors/viewEditor/viewEditor';
import { sharedSettingChange } from '../redux/actions/sharedActions';
import _venue from '../services/venueService';
import { loadComponents } from '../redux/actions/componentsDataActions';
import { loadInputs } from '../redux/actions/inputActions';
import { loadVideoInputs } from '../redux/actions/videoInputActions';
import { loadShared } from '../redux/actions/sharedActions';
import { loadUserPreferences } from '../redux/actions/userPreferencesActions';
import { appSettingChange, appSettingsChange } from '../redux/actions/appSettingsActions';
import _venueData from '../services/venueDataService';
import _venueAccess from '../services/venueAccessService';
import { MOBILE_WIDTH } from './constants';
import MediaControls from './editors/mediaControls/mediaControls';
import { _venueHistory } from '../services/venueHistoryService';
import { COMPONENTS_STORAGE_KEY, getDefaultComponentsSettings } from '../redux/reducers/componentsReducer';
import { SHARED_STORAGE_KEY, getDefaultSharedSettings } from '../redux/reducers/sharedReducer';

const canvasInterface = {
    takeScreenshot: (size, cb) => { },
};

const theme = createTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#ffffff',
        },
        secondary: {
            main: '#b7b7b7',
        },
    },
    typography: {
        fontFamily: 'monospace',
        button: {
            textTransform: "none"
        }
    },
    overrides: {
        MuiFab: {
            root: {
                borderRadius: "5px"
            }
        }
    }
});

class PlayerApp extends React.Component {
    state = {
        initialized: false,
        dataInitialized: false,
    }
    componentDidMount() {
        const _this = this;
        const { id } = this.props.match.params;
        if (id && _this.props.appSettings.projectId != id) {
            _venue.getVenueByChannelId(id)
                .then(project => {
                    if (project) {

                        let appSettings = {
                            projectId: project.id,
                            projectName: project.name,
                            projectLastModified: project.dateModified,
                            projectUserId: project.userId,
                            channelId: id,
                        }

                        if (project.userId == _this.props.user?.userId) {
                            _this.props.appSettingsChange(appSettings);
                            _this.setState({ initialized: true });
                        }
                        else {
                            _venueAccess.getVenueAccessByVenueId(id).then(pas => {
                                if (pas && pas.length) {
                                    let pa = pas.find(pa => pa.user == _this.props.user.email);
                                    appSettings.access = pa?.access || 'read';
                                }
                                else {
                                    appSettings.access = 'read';
                                }
                                _this.props.appSettingsChange(appSettings);
                                _this.setState({ initialized: true });
                            }).catch(_ => {
                                appSettings.access = 'read';
                                _this.props.appSettingsChange(appSettings);
                                _this.setState({ initialized: true });
                            })
                        }

                        _venueData.getVenueData(project.id)
                            .then(projectData => {
                                _this.props.loadComponents(projectData.components);
                                _this.props.loadShared(projectData.shared);
                                _venueHistory.initSnapshot(projectData.components, projectData.shared);

                                _this.props.appSettingChange("projectDataId", projectData.id);
                                _this.props.loadInputs(projectData.inputs);
                                _this.props.loadVideoInputs(projectData.videoInputs);
                                _this.setState({ dataInitialized: true });
                            })

                            .catch(e => {
                                console.error(e);
                                alert("failed to load project data :(");
                                _this.setState({ dataInitialized: true });
                            })
                    }
                    else {
                        _venueHistory.initSnapshot(getDefaultComponentsSettings(), getDefaultSharedSettings());
                        _this.setState({ initialized: true, dataInitialized: true });
                    }
                })
                .catch(e => {
                    console.error(e);
                    alert("failed to load project :(");
                    _this.setState({ initialized: true });
                })


        }
        else if (this.props.appSettings.projectId) {
            if (!_venueHistory.hasHistory()) {
                const components = JSON.parse(sessionStorage.getItem(COMPONENTS_STORAGE_KEY))
                const shared = JSON.parse(sessionStorage.getItem(SHARED_STORAGE_KEY))
                _venueHistory.initSnapshot(components, shared);
            }
            let route = this.props.match.path.replace(':id', this.props.appSettings.projectId)
            this.props.history.push(route);
            _this.setState({ initialized: true, dataInitialized: true });
        }
        else {
            _venueHistory.initSnapshot(getDefaultComponentsSettings(), getDefaultSharedSettings());
            _this.setState({ initialized: true, dataInitialized: true });
        }

        if (_this.props.user.appPreferences) {
            _this.props.loadUserPreferences(_this.props.user.appPreferences);
        }
    }

    undo = () => {
        const _this = this;
        _venueHistory.undo(_this.props.loadComponents, _this.props.loadShared);
    }
    redo = () => {
        const _this = this;
        _venueHistory.redo(_this.props.loadComponents, _this.props.loadShared);
    }

    onDocumentKeyDown = (evt) => {
        const _this = this;
        if (evt.ctrlKey && !evt.shiftKey && evt.key == 'z') {
            if (_venueHistory.canUndo()) {
                _this.undo();
                _this.forceUpdate();
            }
        }
        else if (evt.ctrlKey && evt.shiftKey && evt.key == 'Z') {
            if (_venueHistory.canRedo()) {
                _this.redo();
                _this.forceUpdate();
            }
        }
    }

    // componentDidMount() {
    //     const _this = this;
    //     document.addEventListener('keydown', _this.onDocumentKeyDown);
    //     // const tour = new Shepherd.Tour({
    //     //     defaultStepOptions: {
    //     //         useModalOverlay: true,
    //     //       scrollTo: true, // scrolls the page to the element
    //     //       showCancelLink: true, // displays a 'cancel' link for each step
    //     //       classes: 'shepherd-theme-arrows',
    //     //       arrow: true, // show arrow

    //     //     }
    //     //   });

    //     //   // add a step to the tour that points to the saveButton
    //     //   tour.addStep({
    //     //     id: 'save-button-step',
    //     //     text: 'Click this button to save your project.',
    //     //     attachTo: {
    //     //       element: '#saveButton',
    //     //       on: 'bottom'
    //     //     },
    //     //     buttons: [
    //     //       {
    //     //         text: 'Next',
    //     //         action: tour.next // move to the next step
    //     //       }
    //     //     ]
    //     //   });

    //     //   // start the tour
    //     //   tour.start();
    // }

    componentWillUnmount() {
        const _this = this;
        document.removeEventListener('keydown', _this.onDocumentKeyDown);
    }


    state = {}
    render() {
        const isMobileMode = window.innerWidth <= MOBILE_WIDTH;
        let showMainMenu = this.props.appSettings.view === "edit";
        let showComponentMenu = this.props.appSettings.view === "edit";
        let showMediaControls = this.props.appSettings.view === "edit";
        if (isMobileMode) {
            showComponentMenu = this.props.appSettings.view === "mobile-component-edit";
        }
        return (
            <MuiThemeProvider theme={theme}>
                <CssBaseline />
                <div style={{ position: "fixed", top: "0", left: "0", width: "100vw", height: "100vh", pointerEvents: 'none' }}>
                    {(this.state.initialized && this.state.dataInitialized) ?
                        <Canvas
                            components={this.props.components}
                            shared={this.props.shared}
                            audioInputs={this.props.inputs}
                            videoInputs={this.props.videoInputs}
                            userPreferences={this.props.userPreferences}
                            appSettings={this.props.appSettings}
                            canvasInterface={canvasInterface}
                            playerMode={true}
                        />
                        : (<div style={{ backgroundColor: "rgb(0, 0, 0)", position: "fixed", zIndex: "-1", top: 0, left: 0, width: "100%", height: "100%" }}></div>)
                    }
                </div>



            </MuiThemeProvider >

        );
    }
}

const mapState = (state) => {
    return {
        components: state.components,
        inputs: state.inputs,
        videoInputs: state.videoInputs,
        shared: state.shared,
        userPreferences: state.userPreferences,
        appSettings: state.appSettings,
        user: state.user,
    }
};
const mapDispatch = {
    appSettingChange,
    appSettingsChange,
    sharedSettingChange,
    loadComponents,
    loadShared,
    loadUserPreferences,
    loadInputs,
    loadVideoInputs,
};

export default connect(mapState, mapDispatch)(PlayerApp);