import React from 'react';
import { connect } from 'react-redux';
import { Divider, Box, Grid, IconButton } from '@material-ui/core';
import { appSettingChange } from '../../../redux/actions/appSettingsActions';
import { sharedSettingChange } from '../../../redux/actions/sharedActions';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import EditIcon from '@material-ui/icons/Edit';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import SettingsInputAntennaIcon from '@material-ui/icons/SettingsInputAntenna';
// import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import TuneIcon from '@material-ui/icons/Tune';
import { MOBILE_WIDTH } from '../../constants';
import $ from 'jquery';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import ControlCameraIcon from '@material-ui/icons/ControlCamera';

class ViewEditor extends React.Component {
    state = { message: "", collapse: true }
    onChange = (e, val) => {
        if (val) {
            this.props.appSettingChange("gizmo", undefined);
            this.props.appSettingChange("view", val);
        }
    }
    onCameraChange = (e, val) => {
        if (val) {
            this.props.sharedSettingChange("cameraType", val);
        }
    }

    record = () => {
        const _this = this;
        if (_this.props.appSettings.isRecording) {
            _this.props.appSettingChange("isRecording", false);
        }
        else {
            _this.props.appSettingChange("gizmo", undefined);
            _this.props.appSettingChange("isRecording", true);
        }
    }

    toggleCollapse = () => {
        // this.setState({ collapse: !this.state.collapse });
        this.props.appSettingChange("view", "edit");
    }

    componentDidMount() {
        var timeout = null
        $(document).on('mousemove touchend', function() {
            if (timeout !== null) {
               $('#view-collapsed-button').show();
                clearTimeout(timeout);
            }
        
            timeout = setTimeout(function() {
                 $('#view-collapsed-button').fadeOut();
            }, 3000);
        });
    }

    render() {
        const isViewMode = this.props.appSettings.view == "display";
        const isCollapsedMode = isViewMode && this.state.collapse;
        const isMobileMode = window.innerWidth <= MOBILE_WIDTH;
        const menuWidth = isMobileMode ? "305px" : "265px";
        return (
            <div id="view-menu" style={{pointerEvents:"auto"}}>
                <div id="mySidenav" className="sidenav" style={{
                    width: isViewMode ? (isCollapsedMode ? "25px" : "115px") : menuWidth,
                    position: "fixed",
                    height: "52px",
                    top: "0",
                    paddingTop: "5px",
                    overflow: "hidden",
                    left: isViewMode ? 0 : ("50%"),
                    transform: isViewMode ? "" : "translate(-50%)",
                    margin: "0 auto",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                    border: isCollapsedMode ? "" : "solid rgb(50,50,50) 1px",
                    transition: "all 0.2s ease-in-out",
                    backgroundColor: isCollapsedMode ? "rgba(0,0,0,0)" : "",
                }}>
                    <div>
                        <Box p={1} pt={0}>
                            <div style={{ display: "flex" }}>
                                {
                                    isCollapsedMode
                                        ? ""
                                        : (
                                            <React.Fragment>

                                                <ToggleButtonGroup
                                                    size="small"
                                                    value={this.props.appSettings.view}
                                                    exclusive
                                                    onChange={this.onChange}
                                                >
                                                    <ToggleButton value="edit">
                                                        <EditIcon />
                                                    </ToggleButton>
                                                    {!isMobileMode || <ToggleButton value="mobile-component-edit"><TuneIcon /></ToggleButton>}
                                                    <ToggleButton value="display">
                                                        <FullscreenIcon />
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                                <Divider flexItem orientation="vertical" style={{ margin: "5px" }} />
                                                <ToggleButtonGroup
                                                    size="small"
                                                    value={this.props.appSettings.view}
                                                    exclusive
                                                    onChange={this.record}
                                                >
                                                    <ToggleButton value="recording" style={{ width: "70px", justifyContent: "left" }}>
                                                        <span style={{
                                                            width: "100%",
                                                            textAlign: "left",
                                                            WebkitAnimation: this.props.appSettings.isRecording ? "fadeinout 4s infinite" : "",
                                                            animation: this.props.appSettings.isRecording ? "fadeinout 4s infinite" : "",
                                                        }}>
                                                            <FiberManualRecordIcon style={{ color: this.props.appSettings.isRecording ? "red" : "" }} />
                                                            <span style={{ position: "absolute", marginTop: "2px" }}> REC</span>
                                                        </span>
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                                <Divider flexItem orientation="vertical" style={{ margin: "5px" }} />
                                                <ToggleButtonGroup
                                                    size="small"
                                                    value={this.props.shared.cameraType}
                                                    exclusive
                                                    onChange={this.onCameraChange}
                                                >
                                                    <ToggleButton value="firstperson">
                                                        <DirectionsRunIcon />
                                                    </ToggleButton>
                                                    <ToggleButton value="birdseye">
                                                        <ControlCameraIcon />
                                                    </ToggleButton>
                                                </ToggleButtonGroup>
                                                {
                                                    isViewMode
                                                        ? (
                                                            <IconButton onClick={this.toggleCollapse} style={{ width: "20px", height: "40px", borderRadius: "5px" }}>
                                                                <CloseIcon />
                                                            </IconButton>
                                                        )
                                                        : ""
                                                }
                                            </React.Fragment>

                                        )
                                }
                                {
                                    isCollapsedMode
                                        ? (
                                            <IconButton id="view-collapsed-button" onClick={this.toggleCollapse} style={{ width: "20px", height: "40px", borderRadius: "5px", marginLeft: "-8px" }}>
                                                <MoreVertIcon />
                                            </IconButton>
                                        )
                                        : ""
                                }
                            </div>
                        </Box>
                    </div>
                </div>
            </div >
        );
    }
}

const mapCanvasData = (state) => { return { appSettings: state.appSettings, shared: state.shared } };

const mapDispatch = {
    appSettingChange: appSettingChange,
    sharedSettingChange: sharedSettingChange,
};

export default connect(mapCanvasData, mapDispatch)(ViewEditor);