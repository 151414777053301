import * as BABYLON from 'babylonjs';
import { CustomMaterial } from 'babylonjs-materials';
import { getAttributePropertyShapeValue, getShapeValue, updateBarycentrics } from '../shapes/shapeOptions/utility';
import { getChromaKeyShader } from '../shaders/chromaKeyShader';
import { STREAM_URL } from '../../../../services/config';
import Hls from 'hls.js';

export const getMaterial = (scene, parent, component, color, image, bumpMap, videoTexture, getVideoInputs) => {
    let material;
    switch (component.material.type) {
        case "image": {
            material = new BABYLON.StandardMaterial(`${component.id}-material}`, scene);
            material.diffuseTexture = image;
            material.emissiveTexture = image;
            material.useAlphaFromDiffuseTexture = true;
            break;
        }
        case "wireframe": {
            material = new BABYLON.ShaderMaterial(`${component.id}-mate rial}`, scene, {
                vertex: "wireframe",
                fragment: "wireframe",
            },
                {
                    attributes: ["position", "normal", "uv", "barycentric", "color"],
                    uniforms: ["worldViewProjection", "thickness"],
                    needAlphaBlending: true,
                    needAlphaTesting: true,
                    // needDepthPrePass: true,
                    // separateCullingPass : true
                });
            // let texture = new BABYLON.Texture("textures/amiga.jpg", scene);
            // material.setTexture("textureSampler", texture);
            //material.sideOrientation = BABYLON.Material.ClockWiseSideOrientation;
            //material.transparencyMode = BABYLON.Material.MATERIAL_OPAQUE;
            //material.alphaMode = BABYLON.Engine.ALPHA_ADD;
            //material.hasAlpha  = true;
            material.setFloat("thickness", component.material.wireframethickness);
            let wireframeColor = new BABYLON.Color3.FromHexString(component.material.colorHex).toColor4();
            wireframeColor.a = component.opacity;

            if (parent) {
                if (typeof parent.visibility != "undefined") wireframeColor.a *= parent.visibility;
            }
            material.setColor4("color", wireframeColor);
            // material.emissiveColor = color;
            material.diffuseColor = wireframeColor;
            material.onBindObservable.add((mesh) => {
                mesh.material.setColor4("color", mesh.material.diffuseColor);
            });
            material.checkReadyOnEveryCall = true;
            break;
        }
        case "color": {
            material = new BABYLON.StandardMaterial(`${component.id}-material}`, scene);
            // material.emissiveColor = color;
            //material.wireframe = true;
            material.diffuseColor = color;
            break;
        }
        case "advanced": {
            material = new BABYLON.PBRMaterial("pbr", scene);
            material.albedoColor = color;
            if (component.material.image) {
                material.albedoTexture = image;
            }
            if (component.material.bumpMap) {
                material.bumpTexture = bumpMap;
            }
            material.metallic = component.material.metallic ?? 0;
            material.roughness = component.material.roughness ?? 0;
            material.microSurface = component.material.glossiness ?? 0;

            material.useMicroSurfaceFromReflectivityMapAlpha = true;
            material.clearCoat.isEnabled = component.material.clearCoat > 0;
            material.clearCoat.intensity = component.material.clearCoat;

            material.iridescence.isEnabled = component.material.iridescence > 0;
            material.iridescence.intensity = component.material.iridescence;

            material.anisotropy.isEnabled = component.material.anisotropy > 0;
            material.anisotropy.intensity = component.material.anisotropy;
            material.alpha = component.opacity;
            break;
        }
        case "video": {
            if (component.material.chromakey) {
                const chromakeyColor = new BABYLON.Color3.FromHexString(component.material.chromakeyColor || '#DDDDDD');
                const chromakeyTolerance = typeof component.material.chromakeyTolerance === "undefined" ? 0.25 : component.material.chromakeyTolerance;
                material = new CustomMaterial(`${component.id}-material}`, scene);
                material.Fragment_Before_FragColor(getChromaKeyShader(chromakeyColor, chromakeyTolerance));
            }
            else {
                material = new BABYLON.StandardMaterial(`${component.id}-material}`, scene);
            }

            if (videoTexture.ref) {
                videoTexture.ref.dispose();
                if (videoTexture.ref.video) {
                    const videoEl = videoTexture.ref.video;
                    while (videoEl.firstChild) {
                        videoEl.removeChild(videoEl.lastChild);
                    }
                    videoEl.src = ''
                    videoEl.removeAttribute('src')
                    videoEl.load()
                    videoEl.remove()
                }
            }
            const videoInputs = getVideoInputs();
            let videoInput = videoInputs && videoInputs[component.material.video];
            let type = videoInput?.type;
            let deviceId = videoInput?.deviceId;
            let videoFileUrl = videoInput?.videoFileUrl;
            if (type == "webcam" && deviceId) {
                BABYLON.VideoTexture.CreateFromWebCam(scene, (texture) => {           
                    videoTexture.ref = texture;
                    texture._invertY = false;
                    material.diffuseTexture = texture;
                    material.emissiveTexture = texture;
                }, { deviceId: deviceId });
            }
            else if (type == "file" && videoFileUrl) {
                let texture = new BABYLON.VideoTexture("video2", videoFileUrl, scene);
                videoTexture.ref = texture;
                texture._invertY = false;
                texture.vScale = 1;
                material.diffuseTexture = texture;
                material.emissiveTexture = texture;
            }
            else if (type == "livestream") {
                var video = document.getElementById('livestream');
                let texture = new BABYLON.VideoTexture('livestream', video, scene, true, false, BABYLON.VideoTexture.BILINEAR_SAMPLINGMODE, { poster: video.poster });
                videoTexture.ref = texture;
                texture.vScale = 1;
                material.diffuseTexture = texture;
                material.emissiveTexture = texture;
            }
            break;
        }
    }
    material.alpha = component.opacity;
    material.backFaceCulling = false;

    return material;
}

export const updateMaterial = (component, material, glowLayer, audioFactor, time, parent) => {
    let opacity = getShapeValue(component, 'opacity', audioFactor, time);
    if (parent) {
        if (typeof parent.visibility != "undefined") opacity *= parent.visibility;
    }
    if (material) {
        material.alpha = opacity
    }
    let glowColor = new BABYLON.Color3.FromHexString(component.glowColor || component.material.colorHex).toColor4();
    let glowColorPosition = getShapeValue(component, 'glowColor', audioFactor, time, 0, 100)
    if (glowColorPosition > 0) {
        glowColor = new BABYLON.Color3.FromHexString(component.glowColorColorGradient[glowColorPosition > 99 ? 99 : Math.floor(glowColorPosition)]).toColor4();
    }
    glowColor.a = opacity;
    glowLayer.intensity = getShapeValue(component, 'glow', audioFactor, time)
    glowLayer.customEmissiveColorSelector = function (m, subMesh, material, result) {
        return customGlowLayer(m, component, material, result, glowColor);
    }
    if (component.material.type == "color" || component.material.type == "wireframe" || component.material.type == "advanced") {
        let color = new BABYLON.Color3.FromHexString(component.material.colorHex).toColor4();
        let colorPosition = getAttributePropertyShapeValue(component, 'material', 'colorHex', audioFactor, time, 0, 100)
        if (colorPosition > 0) {
            color = new BABYLON.Color3.FromHexString(component.material.colorGradient[colorPosition > 99 ? 99 : Math.floor(colorPosition)]).toColor4();
        }
        color.a = opacity;
        if (component.material.type == 'wireframe') {
            if (parent) {
                if (typeof parent.visibility != "undefined") color.a *= parent.visibility;
            }
            material.setFloat("thickness", getAttributePropertyShapeValue(component, 'material', 'wireframethickness', audioFactor, time));
            // material.setColor4("color", color);
        }
        // material.emissiveColor = color;
        if (material) {
            material.diffuseColor = color;
            material.albedoColor = color;
        }
    }
}

export const getGlowLayer = (scene, component, mesh, glowColor) => {
    let glowLayer = new BABYLON.GlowLayer(`${component.id}-glow`, scene);
    glowLayer.addIncludedOnlyMesh(mesh);
    glowLayer.intensity = component.glow;
    let gc = new BABYLON.Color4(glowColor.r, glowColor.g, glowColor.b, component.opacity)
    glowLayer.customEmissiveColorSelector = function (m, subMesh, material, result) {
        return customGlowLayer(m, component, material, result, gc);
    }
    return glowLayer
}

export const customGlowLayer = (mesh, component, material, result, glowColor) => {
    if (component.id == mesh.id || (mesh.id.indexOf(mesh.id) != -1 && mesh.id.indexOf("-child") != -1)) {
        if (material && component.material.type == "wireframe") {
            material.setColor4("color", glowColor);
        }
        else {
            result.set(glowColor.r, glowColor.g, glowColor.b, glowColor.a)
        }
    }
}

export const bindMeshAndMaterial = (component, mesh, material, glowLayer) => {
    if (component.material.type == "wireframe") {
        if (!mesh.isFlatShaded && mesh._geometry) {
            mesh.removeVerticesData("barycentric")
            mesh.convertToFlatShadedMesh();
            mesh.originalMeshPositions = [...mesh.getVerticesData("position")];
            mesh.isFlatShaded = true;
        }
        glowLayer.referenceMeshToUseItsOwnMaterial(mesh);
    }
    updateBarycentrics(mesh);
    mesh.material = material;
}
