import React from 'react';
import { connect } from 'react-redux';
import * as BABYLON from 'babylonjs';
import { CAMERA_TYPE_FIRSTPERSON, FFTSIZE } from '../factories/canvasData';
import { sharedSettingChange, sharedSettingsChange } from '../../redux/actions/sharedActions';
import { propChange } from '../../redux/actions/componentsDataActions';
import { degreesToRadians, normalizeRadians, radiansToDegrees } from '../utility/math';
import { audioData, LoadedAudio } from '../factories/audioData';
import { inputPropChange } from '../../redux/actions/inputActions';
import { filterAudioData, rmsFrequencyDomain, rmsTimeDomain } from '../utility/audioFilter';
import { appSettingChange } from '../../redux/actions/appSettingsActions';
import { _assets } from '../factories/assets';
import { _renderClock } from '../factories/renderClock';
import { _frequencyData } from '../factories/frequencyData';
import { _timeDomain } from '../factories/timeDomainData';
import { getSupportedRecordingMimeTypes } from '../utility/utility';
import Universe from './universe';
import HavokPhysics from "@babylonjs/havok";
import { Player } from './components/player/player';
import { STREAM_URL } from '../../services/config';
import Hls from 'hls.js';
import {DeviceUUID} from 'device-uuid';
import _channels from '../../services/channelService';

class Canvas extends React.Component {
    canvas = undefined;
    camera = undefined;
    universe = undefined;
    scene = undefined;
    engine = undefined;
    gizmoManager = undefined;
    background = undefined;
    videoTexture = undefined;
    recorder = undefined;
    isRecording = false;
    playerScene = -1;
    renderedComponents = [];
    audio = {};
    watching = {}
    playlistSceneTime = 30;
    videoInputs = {};
    activeGizmoRenderedComponent = undefined;

    updateCanvas = async () => {
        let _this = this;
        _this.disposeCanvas();
        _this.canvas = document.getElementById("renderCanvas");
        //BABYLON.WebGPUEngine.compatibilityMode = false;
        //_this.engine = await BABYLON.EngineFactory.CreateAsync(_this.canvas);
        _this.engine = new BABYLON.Engine(_this.canvas, true);
        _this.scene = new BABYLON.Scene(_this.engine);
        _this.scene.autoClear = true;
        _this.scene.collisionsEnabled = true;
        _this.scene.enablePhysics(new BABYLON.Vector3(0, -9.8, 0), new BABYLON.HavokPlugin(true, await HavokPhysics()));
        //_this.setCamera(_this.props.shared);

        _this.universe = new Universe(_this.props.userPreferences, _this.engine, _this.appSettingChange, _this.getAppSettings, _this.componentPropChange, _this.getVideoInputs, _this.scene, _this.camera);
        _this.gizmoManager = _this.universe.gizmoManager;

        if (_this.scene) {
            _this.props.audioInputs.forEach(ai => {
                _this.addAudioInput(ai);
            })

            _this.updateUserPreferences(_this.props.userPreferences);
            _this.updateVideo(_this.props.videoInputs, true, _this.props.appSettings);
            _this.updateSharedSettings(_this.props.shared); // dependent on video inputs
            _this.updateAppSettings(_this.props.appSettings);
            _this.updatePlaylistSettings(_this.props.playlist);
            _this.universe.renderComponents(0, _this.props.components);

            if (_this.props.appSettings.selectedComponentId) {
                let selectedRC = _this.universe.renderedComponents.find(rc => rc.id == this.props.appSettings.selectedComponentId);
                if (selectedRC) {
                    _this.universe.updateGizmos(selectedRC, _this.props.appSettings);
                }
            }

            // LIVESTREAM
            let uuid = new DeviceUUID().get();
            let channelId = _this.props.appSettings.channelId;
            if (channelId) {
                var video = document.getElementById('livestream');
                let url = `${STREAM_URL}/api/Watch/${channelId}.m3u8?&deviceId=${uuid}`;
                if (Hls.isSupported()) {
                    var hls = new Hls();
                    hls.loadSource(url);
                    hls.attachMedia(video);
                } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
                    video.src = url;
                    video.play();
                }
            }
           

            _this.scene.registerBeforeRender(function () {
                const currentTime = performance.now();
                _renderClock.publish(currentTime);
                const frequencyData = {};
                const timeDomainData = {};
                // if (_this.props.playerMode) {
                //     _this.playerRun(currentTime);
                // }

                for (let key of Object.keys(_this.audio)) {
                    let index = _this.props.audioInputs.findIndex(a => a.id == key)
                    frequencyData[index] = _this.audio[key].audioAnalyser.getByteFrequencyData();
                    timeDomainData[index] = _this.audio[key].audioAnalyser.getByteTimeDomainData();
                }
                _frequencyData.publish(frequencyData);
                _timeDomain.publish(timeDomainData);

                let animatedRenderedComponentIds = [];
                _this.universe.renderedComponents.forEach(rc => {
                    const component = rc.component;
                    const isScaleGizmoActive = (_this.gizmoManager.scaleGizmoEnabled /* || _this.foregroundGizmoManager.scaleGizmoEnabled || _this.backgroundGizmoManager.scaleGizmoEnabled*/)
                        && _this.props.appSettings.selectedComponentId == component.id;
                    if (!rc.gizmoActive && !isScaleGizmoActive) {
                        let data = {
                            audioFactor: undefined,
                            frequency: undefined,
                            timeDomain: undefined,
                            currentTime: undefined,
                        }

                        if (component.audio?.data == "frequency" && (component.animatesWithoutLinks || component.audio.links.length)) {
                            const relavantFD = frequencyData[component.audio.input];
                            if (relavantFD && relavantFD.length) {
                                data.frequency = filterAudioData(relavantFD, component.audio.filterData);
                                data.audioFactor = rmsFrequencyDomain(data.frequency) / 255;
                            }
                        }
                        else if (component.audio?.data == "time domain" && (component.animatesWithoutLinks || component.audio.links.length)) {
                            const relavantTDD = timeDomainData[component.audio.input];
                            if (relavantTDD && relavantTDD.length) {
                                data.timeDomain = filterAudioData(relavantTDD, component.audio.filterData, -128);
                                data.audioFactor = rmsTimeDomain(data.timeDomain) / 128;
                            }
                        }

                        if (component.time.links.length
                            || (rc.parent && animatedRenderedComponentIds.indexOf(rc.parent.id) != -1)) {
                            data.currentTime = currentTime;
                        }

                        const shouldAnimate = Object.keys(data).some(k => typeof data[k] !== "undefined");
                        if (shouldAnimate) {
                            animatedRenderedComponentIds.push(rc.id);
                            rc.animate(data);
                        }
                    }
                });
            });

            if (_this.props.canvasInterface) {
                _this.props.canvasInterface.takeScreenshot = (size, cb) => {
                    let precision = size / window.innerWidth;
                    BABYLON.Tools.CreateScreenshot(_this.engine, _this.camera, { precision: precision }, cb)
                }
            }

            _this.engine.runRenderLoop(function () {
                let fpsElem = document.getElementById("fps");
                if (fpsElem) {
                    fpsElem.innerHTML = _this.engine.getFps().toFixed();
                }
                _this.scene.render();
            });
        }
    }

    componentWillUnmount() {
        this.disposeCanvas();
    }

    disposeCanvas = () => {
        // const _this = this;
        // if (_this.scene) {
        //     _this.scene.dispose();
        //     _this.scene = undefined;
        // }
        // if (_this.engine) {
        //     _this.engine.dispose();
        //     _this.engine = undefined;
        // }
        // if (_this.universe && _this.universe.renderedComponents.length) {
        //     _this.universe.renderedComponents = [];
        // }
        // if (_this.audio && Object.keys(_this.audio).length) {
        //     Object.keys(_this.audio).forEach(key => {
        //         _this.disposeAudio(key);
        //     })
        //     _this.audio = {};
        // }
    }

    setCamera = (sharedSettings) => {
        const _this = this;
        if (_this.player) {
            _this.player.dispose();
        }

        let oldCamera = _this.camera;
        if (_this.player) {
            _this.player.dispose();
            _this.player = undefined;
        }
        const cameraType = _this.props.playerMode ? CAMERA_TYPE_FIRSTPERSON : sharedSettings.cameraType
        switch (cameraType) {
            case CAMERA_TYPE_FIRSTPERSON: {
                _this.player = new Player(_this.engine, _this.scene, _this.canvas, new BABYLON.Vector3(0, 1, 0));
                _this.camera = _this.player.camera;
                break;
            }
            default: {
                _this.camera = new BABYLON.ArcRotateCamera("camera", degreesToRadians(270), degreesToRadians(90), 10, new BABYLON.Vector3(0, 0, 0), _this.scene);
                _this.camera.attachControl(_this.canvas, true);
                _this.camera.wheelPrecision = 100;
                _this.camera.onViewMatrixChangedObservable.add(function () {
                    let latitude = radiansToDegrees(normalizeRadians(_this.camera.alpha));
                    let longitude = radiansToDegrees(_this.camera.beta);
                    let x = _this.camera.target.x;
                    let y = _this.camera.target.y;
                    let z = _this.camera.target.z;
                    let zoom = (_this.camera.radius - 100) * -1;
                    const didAngleChange = (sharedSettings.cameraLatitude != latitude || sharedSettings.cameraLongitude != longitude);
                    const didZoomChange = sharedSettings.cameraZoom != zoom;
                    const didPositionChange = (sharedSettings.cameraPosition?.x != x || sharedSettings.cameraPosition?.y != y || sharedSettings.cameraPosition?.z != z);
                    if (didAngleChange || didZoomChange || didPositionChange) {
                        if (_this.watching.camera) {
                            clearTimeout(_this.watching.camera)
                        }
                        _this.watching.camera = setTimeout(() => {
                            latitude = radiansToDegrees(normalizeRadians(_this.camera.alpha));
                            longitude = radiansToDegrees(_this.camera.beta);
                            zoom = (_this.camera.radius - 100) * -1;
                            x = _this.camera.target.x;
                            y = _this.camera.target.y;
                            z = _this.camera.target.z;
                            _this.props.sharedSettingsChange({
                                cameraPosition: { x, y, z },
                                cameraZoom: zoom,
                                cameraLatitude: latitude,
                                cameraLongitude: longitude,
                            });
                            clearTimeout(_this.watching.camera)
                        }, 500)
                    }
                });

                // setTarget needs to happen before alpha, beta, radius
                _this.camera.setTarget(new BABYLON.Vector3(sharedSettings.cameraPosition?.x || 0, sharedSettings.cameraPosition?.y || 0, sharedSettings.cameraPosition?.z || 0));
                _this.camera.alpha = degreesToRadians(sharedSettings.cameraLatitude);
                _this.camera.beta = degreesToRadians(sharedSettings.cameraLongitude);
                _this.camera.radius = (sharedSettings.cameraZoom * -1) + 100;
            }
        }

        _this.camera.cameraType = sharedSettings.cameraType;
        if (oldCamera) {
            oldCamera.detachControl();
            oldCamera.dispose();
        }

        _this.universe?.setCamera(_this.camera);
    }

    appSettingChange = (name, value) => {
        const _this = this;
        _this.props.appSettingChange(name, value)
    }

    componentPropChange = (id, name, value) => {
        const _this = this;
        _this.props.componentPropChange(id, name, value)
    }

    getAppSettings = () => {
        const _this = this;
        return _this.props.appSettings;
    }

    getVideoInputs = () => {
        const _this = this;
        return _this.props.videoInputs;
    }

    async componentDidUpdate() {
        await this.updateCanvas();
    }

    shouldComponentUpdate(newProps) {
        const _this = this;
        if (newProps.components != this.props.components) {
            _this.universe?.updateDirtyComponents(newProps.components, newProps.appSettings);
        }

        if (newProps.shared != this.props.shared) {
            _this.updateSharedSettings(newProps.shared);
        }

        if (newProps.userPreferences != this.props.userPreferences) {
            _this.updateUserPreferences(newProps.userPreferences);
        }

        if (newProps.appSettings != this.props.appSettings) {
            if (_this.universe) {
                // on initial load we might not have the universe yet
                const rc = this.universe.renderedComponents.find(m => m.id == newProps.appSettings.selectedComponentId);
                _this.universe.updateGizmos(rc, newProps.appSettings);
            }
            
            _this.updateAppSettings(newProps.appSettings);
        }

        if (newProps.audioInputs != this.props.audioInputs) {
            _this.updateAudio(newProps.audioInputs);
        }

        if (newProps.videoInputs != this.props.videoInputs) {
            _this.updateVideo(newProps.videoInputs, false, newProps.appSettings);
        }

        if (newProps.playlist != this.props.playlist) {
            _this.updatePlaylistSettings(newProps.playlist);
        }

        return false;
    }

    updateUserPreferences = (userPreferences) => {
        const _this = this;
        BABYLON.Engine.audioEngine.setGlobalVolume(userPreferences.globalVolume);
        let fpsElem = document.getElementById("fps");
        if (fpsElem) {
            fpsElem.style.visibility = (userPreferences.displayFPS) ? "" : "hidden";
        }

        _this.updateAspectRatio(userPreferences);
    }

    updateSharedSettings = (sharedSettings) => {
        const _this = this;
        if (sharedSettings.backgroundType != _this.props.shared.backgroundType) {
            _this.disposeBackground();
        }

        switch (sharedSettings.backgroundType) {
            case "color":
                _this.disposeBackground();
                _this.scene.clearColor = BABYLON.Color3.FromHexString(sharedSettings.backgroundColor);
                break;
            case "image":
                if (!_this.background || _this.background.name != sharedSettings.backgroundImage) {
                    _this.disposeBackground();
                    if (sharedSettings.backgroundImage){
                        _assets.get(sharedSettings.backgroundImage).then(asset => {
                            if (asset) {
                                _this.background = new BABYLON.Layer(sharedSettings.backgroundImage, asset.base64, _this.scene, true);
                            }
                        })
                    }              
                }
                break;
            case "video":
                const VIDEO_TEXTURE_NAME = "video-texture";
                let videoInput = _this.videoInputs && _this.videoInputs[sharedSettings.backgroundVideo];
                let type = videoInput?.type;
                if (_this.background && _this.background.type != type) {
                    _this.disposeBackground();
                }
                if (type == "webcam") {
                    let deviceId = videoInput?.deviceId;
                    if (!_this.background || _this.background.name != VIDEO_TEXTURE_NAME || _this.background.deviceId != deviceId) {
                        _this.disposeBackground();
                        _this.background = new BABYLON.Layer(VIDEO_TEXTURE_NAME, null, _this.scene, true);
                        _this.background.deviceId = deviceId;
                        _this.background.type = type;
                        if (deviceId) {
                            BABYLON.VideoTexture.CreateFromWebCam(_this.scene, (videoTexture) => {
                                videoTexture._invertY = false;
                                videoTexture.vScale = 1;
                                _this.videoTexture = videoTexture;
                                _this.background.texture = videoTexture;
                            }, { deviceId: deviceId, minWidth: 1920, minHeight: 1080 });
                        }
                    }
                }
                else if (type == "file") {
                    let videoFileUrl = videoInput?.videoFileUrl;
                    if (!_this.background || _this.background.name != VIDEO_TEXTURE_NAME || _this.background.videoFileUrl != videoFileUrl) {
                        _this.disposeBackground();
                        _this.background = new BABYLON.Layer(VIDEO_TEXTURE_NAME, null, _this.scene, true);
                        _this.background.videoFileUrl = videoFileUrl;
                        _this.background.type = type;
                        if (videoFileUrl) {
                            let videoTexture = new BABYLON.VideoTexture("video", videoFileUrl, _this.scene);
                            videoTexture._invertY = false;
                            videoTexture.vScale = 1;
                            _this.videoTexture = videoTexture;
                            _this.background.texture = videoTexture;
                        }
                    }
                }
                else {
                    _this.disposeBackground();
                }
                break;
        }

        _this.setCamera(sharedSettings);
    }

    updateAppSettings = (appSettings) => {
        const _this = this;
        if (appSettings.isRecording) {
            if (!_this.isRecording) {
                _this.record();
            }
        }
        else {
            if (_this.isRecording) {
                _this.stopRecording();
            }
        }
    }

    updatePlaylistSettings = (playlist) => {
        const _this = this;
        if (playlist) {
            _this.playlistSceneTime = playlist.sceneTime;
        }

        _this.playerScene = -1;
    }

    updateAudio = (audioInputs) => {
        const _this = this;

        if (audioData.dirty.shouldClearCanvas) {
            Object.keys(_this.audio).forEach(key => {
                _this.disposeAudio(key);
            })
        }
        audioData.dirty.delete.forEach(iId => {
            _this.disposeAudio(iId);
        });
        audioData.dirty.add.forEach(iId => {
            let ai = audioInputs.find(i => i.id == iId);
            _this.addAudioInput(ai);
        })
        audioData.dirty.update.forEach(iId => {
            let ai = audioInputs.find(i => i.id == iId);
            if (ai.type != _this.audio[ai.id].type) {
                if (_this.audio[ai.id.type == 'file']) {

                }
                _this.disposeAudio(ai.id);
                _this.addAudioInput(ai);
            }
            else {
                _this.audio[ai.id].audioAnalyser.SMOOTHING = ai.smoothingTimeConstant;
                if (ai.type == 'file') {
                    if (_this.audio[ai.id].sound?.name != `${ai.id}-${ai.audioFileUrl}`) {
                        if (_this.audio[ai.id].sound) {
                            _this.audio[ai.id].soundTrack.removeSound(_this.audio[ai.id].sound);
                            _this.audio[ai.id].sound.dispose();
                        }
                        const onLoad = () => {
                            let trackLength = _this.audio[ai.id].sound?.getAudioBuffer().duration;
                            _this.props.inputPropChange(ai.id, 'status', 'loaded');
                            _this.props.inputPropChange(ai.id, 'trackLength', trackLength);
                            _this.props.inputPropChange(ai.id, 'play', true);
                            _this.audio[ai.id].sound.play(0, ai.currentTime);
                        }
                        _this.props.inputPropChange(ai.id, 'status', 'loading');
                        _this.audio[ai.id].sound = new BABYLON.Sound(`${ai.id}-${ai.audioFileUrl}`, ai.audioFileUrl, _this.scene, onLoad, { loop: false });
                        _this.audio[ai.id].soundTrack.addSound(_this.audio[ai.id].sound);
                        _this.audio[ai.id].soundTrack.connectToAnalyser(_this.audio[ai.id].audioAnalyser);
                    }

                    if (_this.audio[ai.id].sound) {
                        _this.audio[ai.id].sound.setVolume(ai.volume);
                        if (_this.audio[ai.id].sound.isPlaying && !ai.play) {
                            _this.props.inputPropChange(ai.id, 'currentTime', ai.currentTime + _this.audio[ai.id].sound.currentTime);
                            _this.audio[ai.id].sound.pause();
                        }
                        else if (_this.audio[ai.id].sound.isPaused && ai.play) {
                            _this.audio[ai.id].sound.play();
                            _this.audio[ai.id].sound.stop();
                            _this.audio[ai.id].sound.play(0, ai.currentTime);
                        }
                    }
                }
                else if (ai.type == 'mic') {
                    if (_this.audio[ai.id].deviceId != ai.deviceId) {
                        if (_this.audio[ai.id].sound) {
                            _this.audio[ai.id].soundTrack.removeSound(_this.audio[ai.id].sound);
                            _this.audio[ai.id].sound.dispose();
                        }
                        navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: ai.deviceId } } })
                            .then((stream) => {
                                _this.audio[ai.id].type = 'mic';
                                _this.audio[ai.id].sound = new BABYLON.Sound(ai.id, stream, _this.scene, null, { streaming: true, autoplay: true, loop: true });
                                _this.audio[ai.id].soundTrack.addSound(_this.audio[ai.id].sound);
                                _this.audio[ai.id].sound.setVolume(ai.volume);
                            })
                            .catch(err => {
                                alert('mic not supported.');
                            });
                    }
                    else {
                        if (_this.audio[ai.id].sound) {
                            _this.audio[ai.id].sound.setVolume(ai.volume);
                        }
                    }
                }
            }
        })

        audioData.dirty.clear();
    }

    updateVideo = (videoInputs, initialLoad = false, appSettings) => {
        const _this = this;
        _this.videoInputs = videoInputs;
        if (!initialLoad) {
            _this.universe.renderedComponents.forEach(rc => rc.dispose());
            _this.universe.renderedComponents = [];
            _this.universe.renderComponents(0, _this.props.components);
            if (_this.props.appSettings.selectedComponentId) {
                let rc = _this.universe.renderedComponents.find(rc => rc.component.id == _this.props.appSettings.selectedComponentId);
                _this.universe.updateGizmos(rc, appSettings);
            }
            _this.updateSharedSettings(_this.props.shared);
        }
    }

    addAudioInput = (ai) => {
        const _this = this;
        _this.audio[ai.id] = {};
        _this.audio[ai.id].audioAnalyser = new BABYLON.Analyser(_this.scene);
        _this.audio[ai.id].audioAnalyser.FFT_SIZE = FFTSIZE;
        _this.audio[ai.id].audioAnalyser.SMOOTHING = ai.smoothingTimeConstant;
        _this.audio[ai.id].soundTrack = new BABYLON.SoundTrack(_this.scene);
        if (ai.type == "file") {
            _this.audio[ai.id].type = 'file';
            if (ai.audioFileUrl) {
                const onLoad = () => {
                    let trackLength = _this.audio[ai.id].sound?.getAudioBuffer().duration;
                    _this.props.inputPropChange(ai.id, 'status', 'loaded');
                    _this.props.inputPropChange(ai.id, 'trackLength', trackLength);
                    _this.props.inputPropChange(ai.id, 'play', true);
                    _this.audio[ai.id].sound.play(0, ai.currentTime);
                }
                _this.props.inputPropChange(ai.id, 'status', 'loading');
                _this.audio[ai.id].sound = new BABYLON.Sound(`${ai.id}-${ai.audioFileUrl}`, ai.audioFileUrl, _this.scene, onLoad, { loop: false });
                _this.audio[ai.id].soundTrack.addSound(_this.audio[ai.id].sound);
                _this.audio[ai.id].soundTrack.connectToAnalyser(_this.audio[ai.id].audioAnalyser);
            }
        }
        else if (ai.type == "mic") {
            _this.audio[ai.id].deviceId = ai.deviceId;
            if (ai.deviceId) {
                navigator.mediaDevices.getUserMedia({ audio: { deviceId: { exact: ai.deviceId } } })
                    .then((stream) => {
                        _this.audio[ai.id].type = 'mic';
                        _this.audio[ai.id].sound = new BABYLON.Sound(ai.id, stream, _this.scene, null, { streaming: true, autoplay: true, loop: true });
                        _this.audio[ai.id].soundTrack.addSound(_this.audio[ai.id].sound);
                        _this.audio[ai.id].sound.setVolume(ai.volume);
                        _this.audio[ai.id].soundTrack.connectToAnalyser(_this.audio[ai.id].audioAnalyser);
                    })
                    .catch(err => {
                        alert('mic not supported.');
                    });
            }
        }

        audioData.loaded[ai.id] = new LoadedAudio();
    }

    updateAudioTimes = () => {
        const _this = this;
        Object.keys(_this.audio).forEach(key => {
            if (_this.audio[key].type == 'file' && _this.audio[key].sound && _this.audio[key].sound.isPlaying) {
                const currentTime = _this.audio[key].sound.currentTime;
                if (audioData.loaded[`${key}-input`]) {
                    audioData.loaded[`${key}-input`].time.update(currentTime);
                }
            }
        })
        setTimeout(_this.updateAudioTimes, 100)
    }

    loadFile = () => {
        const _this = this;

    }

    disposeAudio = (key) => {
        const _this = this;
        _this.audio[key].soundTrack.removeSound(_this.audio[key].sound);
        _this.audio[key].audioAnalyser.dispose();
        if (_this.audio[key].sound)
            _this.audio[key].sound.dispose();

        // _this.audio[key].soundTrack.dispose(); // crashing things for some reason when switching from mic to file....
        delete audioData.loaded[`${key}-input`];
        delete _this.audio[key]
    }

    disposeBackground = () => {
        const _this = this;
        if (_this.videoTexture) {
            _this.videoTexture.dispose();
            if (_this.videoTexture.video) {
                const videoEl = _this.videoTexture.video;
                while (videoEl.firstChild) {
                    videoEl.removeChild(videoEl.lastChild);
                }
                videoEl.src = ''
                videoEl.removeAttribute('src')
                videoEl.load()
                videoEl.remove()
            }
            _this.videoTexture = undefined;
        }
        if (_this.background) {
            _this.background.dispose();
            _this.background = undefined;
        }
    }

    getMediaStream = () => {
        const _this = this;
        const canvas = document.getElementById("renderCanvas");
        const canvasStream = canvas.captureStream(_this.props.userPreferences.fps);
        const audioDestinationStream = BABYLON.Engine.audioEngine.audioContext.createMediaStreamDestination();
        BABYLON.Engine.audioEngine.masterGain.connect(audioDestinationStream);
        const audioStream = audioDestinationStream.stream;
        return new MediaStream([canvasStream.getTracks()[0], audioStream.getTracks()[0]]);
    }

    record = () => {
        const _this = this;
        _this.isRecording = true
        const mediaStream = _this.getMediaStream();
        // replace with WebRTC connection
        _this.recorder = new MediaRecorder(mediaStream, {
            mimeType: _this.props.userPreferences.videoFormat,
            videoBitsPerSecond: 40000000,
        });
        _this.recordedData = [];
        _this.recorder.ondataavailable = (e) => {
            if (e.data.size > 0) {
                _this.recordedData.push(e.data);
            }
        };
        _this.recorder.start();
        //
    }

    stopRecording = () => {
        const _this = this;
        let videoFormat = getSupportedRecordingMimeTypes().find(m => m.mime == _this.props.userPreferences.videoFormat);
        _this.isRecording = false;
        _this.recorder.stop();
        _this.recorder.stream.getTracks().forEach(t => t.stop());
        setTimeout(() => {
            const file = new Blob(_this.recordedData, { type: _this.props.userPreferences.videoFormat });
            const url = URL.createObjectURL(file);
            const a = document.createElement("a");
            a.href = url;
            a.download = `recording.${videoFormat.extension}`;
            a.click();
            URL.revokeObjectURL(url);
        }, 100);
    }

    playerRun = (currentTime) => {
        const _this = this;
        const timeInterval = _this.playlistSceneTime * 1000;
        const sceneNumber = Math.floor(currentTime / timeInterval);
        if (_this.playerScene < sceneNumber) {
            _this.playerScene = sceneNumber;
            _this.props.playerUpdateScene(sceneNumber);
        }
    }

    updateAspectRatio = (userPreferences) => {
        const _this = this;
        let canvasHeight = "100%";
        let canvasWidth = "100%";
        let canvas = document.getElementById('renderCanvas');
        const aspectRatio = userPreferences?.aspectRatio;
        if (aspectRatio) {
            let aspectWidth = aspectRatio.split(":")[0];
            let aspectHeight = aspectRatio.split(":")[1];
            let canvasHeight = aspectHeight * window.innerWidth / aspectWidth;
            let canvasWidth = window.innerWidth;
            if (canvasHeight > window.innerHeight) {
                canvasHeight = window.innerHeight;
                canvasWidth = aspectWidth * window.innerHeight / aspectHeight;
            }
            canvasHeight = `${Math.ceil(canvasHeight)}px`
            canvasWidth = `${Math.ceil(canvasWidth)}px`
            canvas.style.height = canvasHeight;
            canvas.style.width = canvasWidth;
            _this.engine.resize();
        }
        else {
            canvas.style.height = canvasHeight;
            canvas.style.width = canvasWidth;
            _this.engine.resize();
        }
    }

    async componentDidMount() {
        const _this = this;
        window.addEventListener("resize", () => {
            _this.updateAspectRatio(_this.props.userPreferences);
            // _this.engine.resize();
        });
        await _this.updateCanvas();
        _this.updateAudioTimes();
    }

    render() {
        const _this = this;
        const fpsStyle = {
            position: "absolute",
            backgroundColor: "black",
            borderRadius: "10px",
            textAlign: "center",
            fontSize: "16px",
            color: "white",
            top: "5px",
            right: "5px",
            width: "70px",
            height: "25px",
            zIndex: "1000",
        }

        let canvasHeight = "100%";
        let canvasWidth = "100%";

        return (
            <React.Fragment>
                <div id="fps" style={fpsStyle}>0</div>
                {/* <video id="canvasVideo" style={{ display: "none" }} autoPlay={true} playsInline src={`${STREAM_URL}/api/Watch/${"82057251-d92e-4794-a063-08db21e0774a"}.m3u8?&deviceId=${"1234"}`} poster={_assets.getFileUrl(this.state.channelPictureId)}></video> */}
                <video id="livestream" style={{ display: "none" }} poster={_channels.getChannelPictureByChannelId(this.props.appSettings.channelId)}></video>
                <div style={{ backgroundColor: "rgb(20, 20, 20)", position: "fixed", zIndex: "-1", top: 0, left: 0, width: "100%", height: "100%" }}>
                    <canvas id="renderCanvas" style={{ margin: "0 auto", position: "fixed", width: canvasWidth, height: canvasHeight, zIndex: "-1", left: "50%", top: "50%", transform: "translate(-50%, -50%)", pointerEvents: "auto", outline: 'none' }} />
                </div>
            </React.Fragment>

        );
    }
}


const mapState = (state) => { return {} };
const actions = {
    sharedSettingChange,
    sharedSettingsChange,
    componentPropChange: propChange,
    inputPropChange: inputPropChange,
    appSettingChange: appSettingChange,
};
export default connect(mapState, actions)(Canvas)